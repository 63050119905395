<template>
  <div class="block-body">
    <div class="article-list">
      <div class="article-nav">
        {{ ItemData.title == '' ? '标题' : ItemData.title }}
        <a href="#" class="more">更多</a>
      </div>
      <div class="list-items-box">
        <div class="list-item" v-for="(item) in 6" :key="item">山一程，水一程，身向榆关那畔行，夜深千帐灯。风一更，雪一更，聒碎乡心梦不成，故园无此声。</div>
      </div>
    </div>
    <div class="block-bar">
      <div class="bar-title">块</div>
      <div class="bar-item" @click="editBlock()">设置</div>
      <div class="bar-item" @click="deleteBlock()">清空</div>
    </div>
  </div>

  <a-drawer :width="360" title="模块设置" placement="left" class="drawer-container" :maskClosable="false"
    :visible="visibleBlock" @close="onCloseBlock">
    <template #extra>
      <a-button style="margin-right: 8px" @click="onCloseBlock">取消</a-button>
      <a-button type="primary" @click="onCloseBlock">确定</a-button>
    </template>
    <div class="block-style-data">
      <a-card title="数据" :bordered="false">
        <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" autocomplete="off">
          <a-form-item label="模块结构">
            <a-select v-model:value="ItemData.subName" @change="changeDataType">
              <a-select-option :value="item.value" v-for="(item, index) in dataType" :key="index">{{ item.title }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="标题">
            <a-input v-model:value="ItemData.title" placeholder="请输入标题" />
          </a-form-item>
          <a-form-item label="选择数据">
            <a-select v-model:value="ItemData.formID" :options="optionsBlock"
              :field-names="{ label: 'name', value: 'id', options: 'children' }">
            </a-select>
          </a-form-item>
        </a-form>
      </a-card>
      <a-card title="样式" :bordered="false">
        <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" autocomplete="off">
          <!-- <a-form-item label="模块名称">
            <a-input v-model:value="ItemStyle.blockName" placeholder="请输入模块名称" />
          </a-form-item>
          <a-form-item label="外边距">
            <a-slider v-model:value="ItemStyle.blockStyle.margin" :min="0" :max="100" />
          </a-form-item>
          <a-form-item label="内边距">
            <a-slider v-model:value="ItemStyle.blockStyle.padding" :min="0" :max="100" />
          </a-form-item> -->
        </a-form>
      </a-card>
    </div>
  </a-drawer>

</template>
<script>
import { defineComponent, ref, computed, onMounted } from "vue";
import * as Api from "../api";
import "./style/common.less";
import "./style/article.less";
export default defineComponent({
  props: {
    data: Object,
    style: Object,
  },
  setup(props, { emit }) {
    const ItemData = computed({
      get: () => props.data,
      set: (value) => emit("update:data", value),
    });

    const ItemStyle = computed({
      get: () => props.style,
      set: (value) => emit("update:style", value),
    });

    const visibleBlock = ref(false);

    // 设置模块
    const editBlock = () => {
      visibleBlock.value = true;
    };

    // 置空模块
    const deleteBlock = () => {

    };

    const optionsBlock = ref([]);

    //切换数据结构
    const changeDataType = (value) => {
      // 根据选择的数据类型请求数据
      Api.get_block_data({
        type: value,
      }).then((res) => {
        console.log(res);
        optionsBlock.value = res.data;
      });
    };

    const onCloseBlock = () => {
      visibleBlock.value = false;
    };

    // 模块内容类型
    const dataType = [
      { title: "文档列表", value: "article" },
      { title: "文档详情", value: "article_detail" },
      { title: "幻灯片", value: "advs" },
      { title: "图片", value: "image" },
      { title: "富文本", value: "rich" },
      { title: "TAB标签页", value: "tab" },
      { title: "地图", value: "map" },
      { title: "自定义", value: "idefined" },
    ];

    onMounted(() => { });

    return { ItemData, ItemStyle, visibleBlock, editBlock, deleteBlock, changeDataType, onCloseBlock, optionsBlock, dataType };
  },
});
</script>
<style lang="less" scoped>
.article-list {
  min-height: 200px;
  border: 1px solid #f0f0f0;

  .article-nav {
    font-size: 18px;
    padding: 10px 20px;
    border-bottom: 1px solid #f0f0f0;

    .more {
      font-size: 14px;
      display: block;
      float: right;
      color: #333333;
    }
  }

  .list-items-box {
    padding: 10px;

    .list-item {
      width: 100%;
      margin: 6px 0;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
<template>
  <span @click="openModel" class="editor-txt">{{ txt }}</span>
  <a-modal v-model:visible="web_visible" title="网页编辑器" width="100%" wrap-class-name="full-modal" @ok="handleOk">
    <div class="web-editor">
      <div class="editor-module">
        <a-collapse v-model:activeKey="activeKey">
          <a-collapse-panel key="1" header="组件列表">
            <div class="editor-bar">
              sssss
            </div>
          </a-collapse-panel>
        </a-collapse>
      </div>
      <div class="editor-header">这里是Header</div>
      <div class="editor-body">
        <a-carousel arrows>
          <template #prevArrow>
            <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
              <left-circle-outlined />
            </div>
          </template>
          <template #nextArrow>
            <div class="custom-slick-arrow" style="right: 10px">
              <right-circle-outlined />
            </div>
          </template>
          <div>
            <h3>1</h3>
          </div>
          <div>
            <h3>2</h3>
          </div>
          <div>
            <h3>3</h3>
          </div>
          <div>
            <h3>4</h3>
          </div>
        </a-carousel>
        <div class="body-content">
          <p v-for="item in 12" :key="item">这是一行</p>
        </div>
      </div>
      <div class="editor-footer">这里是Footer</div>
    </div>
  </a-modal>
</template>
<script>
import { defineComponent, ref } from "vue";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons-vue";
import "./style/web.less";
export default defineComponent({
  components: {
    LeftCircleOutlined,
    RightCircleOutlined,
  },
  props: {
    data: { type: Object, default: () => {} },
    txt: { type: String, default: "打开" },
  },
  setup() {
    const web_visible = ref(false);

    // 点击文字打开弹框全屏
    const openModel = () => {
      web_visible.value = true;
    };

    // 点击确定
    const handleOk = (e) => {
      console.log(e);
      web_visible.value = false;
    };

    // 轮播切换
    const onChange = (current) => {
      console.log(current);
    };
    return { web_visible, openModel, handleOk, onChange, activeKey: ref("1") };
  },
});
</script>
<style scoped>
/* For demo */
.ant-carousel :deep(.slick-slide) {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
  z-index: 1;
}
.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}
.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}
</style>
<template>
  <div class="design-phone">
    <div class="phone-content">
      <div
        :class="data.selectedIndex === -1 ? 'phone-top selected' : 'phone-top'"
        @click="switchModule(-1)"
        :style="{ backgroundColor: data.page.style.titleBackgroundColor }"
      >
        <h3
          class="phone-title"
          :style="{ color: data.page.style.titleTextColor }"
        >
          {{ data.page.params.title }}
        </h3>
      </div>
      <div
        class="phone-main"
        :style="{ backgroundColor: data.page.style.backgroundColor }"
      >
        <draggable
          v-model="list"
          group="phone"
          @start="drag = true"
          @end="drag = false"
          @update="draggableModule"
          item-key="id"
        >
          <template #item="{ element, index }">
            <div
              :class="
                data.selectedIndex === index
                  ? 'module-item selected'
                  : 'module-item'
              "
              @click="switchModule(index)"
            >
              <!-- 图片轮播 -->
              <div class="module-banner" v-if="element.type == 'banner'">
                <div
                  :style="{
                    margin: `${element.style.marginTopBottom}px ${element.style.marginLeftRight}px`,
                  }"
                >
                  <a-carousel arrows autoplay>
                    <template #prevArrow>
                      <div class="control-arrow control-arrow-left">
                        <LeftCircleOutlined />
                      </div>
                    </template>
                    <template #nextArrow>
                      <div class="control-arrow control-arrow-right">
                        <RightCircleOutlined />
                      </div>
                    </template>
                    <div
                      v-for="(item, dataIndex) in element.data"
                      :key="dataIndex"
                      v-show="dataIndex <= 1"
                    >
                      <a-image
                        :style="{
                          width: `${375 - element.style.marginLeftRight}px`,
                          height: `${element.style.height}px`,
                        }"
                        :key="`${index}_${dataIndex}_img`"
                        :preview="false"
                        :src="item.imgUrl"
                      />
                    </div>
                  </a-carousel>
                </div>
              </div>
              <!-- 图片组 -->
              <div class="module-image" v-else-if="element.type == 'image'">
                <div
                  :style="{
                    padding: `${element.style.paddingTopBottom}px ${element.style.paddingLeftRight}px`,
                  }"
                >
                  <a-image
                    v-for="item in element.data"
                    :key="item"
                    :preview="false"
                    :src="item.imgUrl"
                  />
                </div>
              </div>
              <!-- 图片橱窗 -->
              <div class="module-window" v-else-if="element.type == 'window'">
                <div
                  class="window-body"
                  :style="{
                    backgroundColor: element.style.background,
                  }"
                >
                  <div
                    class="window-item"
                    v-for="(item, index) in element.data"
                    :key="index"
                    :style="{
                      padding: `${element.style.paddingTopBottom}px ${element.style.paddingLeftRight}px`,
                    }"
                  >
                    <a-image :preview="false" :src="item.imgUrl" />
                  </div>
                </div>
              </div>
              <!-- 视频组 -->
              <div class="module-video" v-else-if="element.type == 'video'">
                <video
                  :style="{ height: `200px` }"
                  src="http://wxsnsdy.tc.qq.com/105/20210/snsdyvideodownload?filekey=30280201010421301f0201690402534804102ca905ce620b1241b726bc41dcff44e00204012882540400.mp4"
                  :poster="false"
                  controls
                >
                  您的浏览器不支持 video 标签
                </video>
              </div>
              <!-- 文章组 -->
              <div class="module-article" v-else-if="element.type == 'article'">
                <div
                  class="article-item"
                  v-for="(item, index) in element.data"
                  :key="index"
                  :style="{
                    margin: `${element.style.marginTopBottom}px ${element.style.marginLeftRight}px`,
                    padding: `${element.style.paddingTopBottom}px ${element.style.paddingLeftRight}px`,
                  }"
                >
                  <template v-if="item.image">
                    <a-row>
                      <a-col :span="16">
                        <div class="item-body">
                          <h3 class="title">{{ item.title }}</h3>
                          <div class="author">
                            <span>中国新闻</span
                            ><span class="date"
                              >{{ item.views_num }}次浏览</span
                            >
                          </div>
                        </div>
                      </a-col>
                      <a-col :span="8">
                        <div class="cover-image">
                          <a-image
                            class=""
                            :preview="false"
                            :src="item.image"
                            :style="{
                              borderRadius: `${element.style.coverRadius}px`,
                            }"
                          />
                        </div>
                      </a-col>
                    </a-row>
                  </template>
                  <div class="item-body" v-else>
                    <h3 class="title">{{ item.title }}</h3>
                    <div class="author">
                      <span>中国新闻</span
                      ><span class="date">{{ item.views_num }}次浏览</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 搜索栏 -->
              <div class="module-search" v-else-if="element.type == 'search'">
                <div :class="element.style.searchStyle">
                  <div
                    :style="{
                      textAlign: element.style.textAlign,
                      padding: `${element.style.paddingTopBottom}px ${element.style.paddingLeftRight}px`,
                    }"
                  >
                    <a-input
                      :placeholder="element.params.placeholder"
                      style="border: none"
                    >
                      <template #prefix>
                        <SearchOutlined />
                      </template>
                    </a-input>
                  </div>
                </div>
              </div>
              <!-- 店铺公告 -->
              <div class="module-notice" v-else-if="element.type == 'notice'">
                <div
                  class="notice-body"
                  :style="{
                    margin: `${element.style.marginTopBottom}px ${element.style.marginLeftRight}px`,
                    backgroundColor: element.style.background,
                    color: element.style.textColor,
                  }"
                >
                  <NotificationOutlined class="notice-icon" /><span>{{
                    element.params.text
                  }}</span>
                </div>
              </div>
              <!-- 导航组 -->
              <div class="module-navBar" v-else-if="element.type == 'navBar'">
                {{ element.type }}
              </div>
              <!-- 商品组 -->
              <div class="module-goods" v-else-if="element.type == 'goods'">
                {{ element.type }}
              </div>
              <!-- 在线客服 -->
              <div class="module-service" v-else-if="element.type == 'service'">
                {{ element.type }}
              </div>
              <!-- 富文本 -->
              <div
                class="module-richText"
                v-else-if="element.type == 'richText'"
              >
                <div
                  :style="{
                    background: element.style.background,
                    padding: `${element.style.marginTopBottom}px ${element.style.marginLeftRight}px`,
                  }"
                  v-html="element.params.content"
                ></div>
              </div>
              <!-- 辅助空白 -->
              <div class="module-blank" v-else-if="element.type == 'blank'">
                <div
                  class="blan-body"
                  :style="{
                    height: `${element.style.height}px`,
                    backgroundColor: element.style.background,
                  }"
                ></div>
              </div>
              <!-- 辅助线 -->
              <div class="module-guide" v-else-if="element.type == 'guide'">
                <div
                  class="guide-body"
                  :style="{
                    padding: `${element.style.paddingTopBottom}px 0`,
                    backgroundColor: element.style.background,
                  }"
                >
                  <p
                    class="line"
                    :style="{
                      borderTopWidth: element.style.lineHeight + 'px',
                      borderTopColor: element.style.lineColor,
                      borderTopStyle: element.style.lineStyle,
                    }"
                  ></p>
                </div>
              </div>
              <div v-else>{{ element.type }}</div>
              <!-- 删除操作 -->
              <div class="delete-btn">
                <a-button type="text" @click.stop="deleleModule(index)"
                  >删除</a-button
                >
              </div>
            </div>
          </template>
        </draggable>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import {
  LeftCircleOutlined,
  RightCircleOutlined,
  SearchOutlined,
  NotificationOutlined,
} from "@ant-design/icons-vue";
import draggable from "vuedraggable";
import "./style/phone.less";
export default defineComponent({
  name: "Phone",
  props: {
    data: { type: Object, default: () => {} },
  },
  components: {
    draggable,
    LeftCircleOutlined,
    RightCircleOutlined,
    SearchOutlined,
    NotificationOutlined,
  },
  setup(props, context) {
    // console.log(props.data);

    // 拖拽组件排序
    const draggableModule = (e) => {
      console.log(e);
    };

    // 切换选中的组件
    const switchModule = (index) => {
      context.emit("switchModule", index);

      // console.log(props.data.selectedIndex);
    };

    // 删除组件
    const deleleModule = (index) => {
      context.emit("deleleModule", index);
    };

    return {
      drag: ref(false),
      list: props.data.items,
      draggableModule,
      switchModule,
      deleleModule,
    };
  },
});
</script>
<template>
  <div class="iempty-box">

  </div>
</template>
<style lang="less" scoped>
.iempty-box {
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid #f0f0f0;
}
</style>
<template>
  <div class="web-block">
    <div class="block-line-div" v-for="(item, index) in comItems" :key="index">
      <a-row class="block-line" :gutter="[item.blockStyle.gutter]"
        :style="{ backgroundColor: item.blockStyle.backgroundColor, margin: item.blockStyle.margin + 'px', paddingTop: item.blockStyle.paddingTop + 'px', paddingBottom: item.blockStyle.paddingBottom + 'px' }">
        <a-col v-for="(block, col) in item.layNumber" :key="col" :span="parseInt(24 / item.layNumber)">
          <div class="block-box">
            <!----模块开始---->
            <ImageGraph v-if="item.blockData[col].subName == 'image'" :data="item.blockData[col].data"
              :style="item.blockData[col].style" />
            <Advs v-else-if="item.blockData[col].subName == 'advs'" :data="item.blockData[col].data"
              :style="item.blockData[col].style" />
            <RichEditor v-else-if="item.blockData[col].subName == 'rich'" :data="item.blockData[col].data"
              :style="item.blockData[col].style" />
            <Article v-else-if="item.blockData[col].subName == 'article'" :data="item.blockData[col].data"
              :style="item.blockData[col].style" />
            <TabList v-else-if="item.blockData[col].subName == 'tab'" :data="item.blockData[col].data"
              :style="item.blockData[col].style" />
            <Idefined v-else-if="item.blockData[col].subName == 'idefined'" :data="item.blockData[col].data"
              :style="item.blockData[col].style" />
            <Iempty v-else />
            <!----模块结束---->
          </div>
        </a-col>
      </a-row>
      <div class="line-bar">
        <div class="bar-title">布局</div>
        <div class="bar-item" @click="editLayout(item, index)">设置</div>
        <div class="bar-item">编辑</div>
        <div class="bar-item">
          <a-tooltip>
            <template #title>复制</template>
            <span class="bar-item-text">
              <CopyOutlined />
            </span>
          </a-tooltip>
        </div>
        <div class="bar-item">
          <a-tooltip>
            <template #title>上移</template>
            <span class="bar-item-text">
              <ArrowUpOutlined />
            </span>
          </a-tooltip>
        </div>
        <div class="bar-item">
          <a-tooltip>
            <template #title>下移</template>
            <span class="bar-item-text">
              <ArrowDownOutlined />
            </span>
          </a-tooltip>
        </div>
      </div>
    </div>
  </div>
  <a-modal ref="modalRef" class="layout-layer" :width="360" :mask="false" :maskClosable="false"
    v-model:visible="visible" :wrap-style="{ overflow: 'hidden' }" @ok="handleOk">
    <div class="layout-editor">
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" autocomplete="off">
        <a-form-item label="模块名称">
          <a-input v-model:value="comItems[comActive].blockName" placeholder="请输入模块名称" />
        </a-form-item>
        <a-form-item label="上外边距">
          <a-slider v-model:value="comItems[comActive].blockStyle.paddingTop" :min="0" :max="200" />
        </a-form-item>
        <a-form-item label="下外边距">
          <a-slider v-model:value="comItems[comActive].blockStyle.paddingBottom" :min="0" :max="200" />
        </a-form-item>
        <a-form-item label="栅格间距">
          <a-slider v-model:value="comItems[comActive].blockStyle.gutter" :min="0" :max="200" />
        </a-form-item>
        <a-form-item label="内边距">
          <a-slider v-model:value="comItems[comActive].blockStyle.margin" :min="0" :max="200" />
        </a-form-item>
        <a-form-item label="背景颜色">
          <ColorPicker picker-type="chrome" format="hex" v-model:pureColor="
            comItems[comActive].blockStyle.backgroundColor
          " />
          <span class="color-select-code">Hex：{{ comItems[comActive].blockStyle.backgroundColor }}</span>
        </a-form-item>
      </a-form>
    </div>
    <template #title>
      <div ref="modalTitleRef" class="layout-editor-drag">
        <MenuOutlined />
        <span class="layout-editor-title">模块布局</span>
      </div>
    </template>
    <template #footer>
      <div class="layout-editor-footer">
        <div class="layout-editor-footer-btn layout-editor-footer-ok" @click="okLayout">
          确定
        </div>
        <div class="layout-editor-footer-btn layout-editor-footer-cancel" @click="cancelLayout">
          取消
        </div>
      </div>
    </template>
    <template #modalRender="{ originVNode }">
      <div :style="transformStyle">
        <component :is="originVNode" />
      </div>
    </template>
  </a-modal>
</template>
<script>
import {
  defineComponent,
  // reactive,
  ref,
  computed,
  onMounted,
  watch,
  watchEffect,
} from "vue";
import {
  CopyOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  MenuOutlined,
} from "@ant-design/icons-vue";
import { ColorPicker } from "vue3-colorpicker";
import { useDraggable } from "@vueuse/core";
import {
  ImageGraph,
  Advs,
  RichEditor,
  Article,
  TabList,
  Idefined,
  Iempty,
} from "./block";
import "./style/block.less";
export default defineComponent({
  components: {
    ColorPicker,
    CopyOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined,
    MenuOutlined,
    ImageGraph,
    Advs,
    RichEditor,
    Article,
    TabList,
    Idefined,
    Iempty,
  },
  props: {
    dataBlock: Object,
    activeBlock: Number,
  },
  setup(props, { emit }) {
    console.log(props.dataBlock);
    console.log(props.activeBlock);

    // console.log(props.dataBlock);
    // console.log(props.activeBlock);

    const comItems = computed({
      get: () => props.dataBlock,
      set: (value) => emit("update:dataBlock", value),
    });

    // 当前活跃的布局模块
    const comActive = ref(props.activeBlock);

    const editLayout = (item, index) => {
      comActive.value = index;
      visible.value = true;
    };

    const visible = ref(false);
    const modalTitleRef = ref(null);

    const showModal = () => {
      visible.value = true;
    };

    const { x, y, isDragging } = useDraggable(modalTitleRef);

    const handleOk = (e) => {
      console.log(e);
      visible.value = false;
    };

    const startX = ref(0);
    const startY = ref(0);
    const startedDrag = ref(false);
    const transformX = ref(0);
    const transformY = ref(0);
    const preTransformX = ref(0);
    const preTransformY = ref(0);
    const dragRect = ref({
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    });
    watch([x, y], () => {
      if (!startedDrag.value) {
        startX.value = x.value;
        startY.value = y.value;
        const bodyRect = document.body.getBoundingClientRect();
        const titleRect = modalTitleRef.value.getBoundingClientRect();
        dragRect.value.right = bodyRect.width - titleRect.width;
        dragRect.value.bottom = bodyRect.height - titleRect.height;
        preTransformX.value = transformX.value;
        preTransformY.value = transformY.value;
      }

      startedDrag.value = true;
    });
    watch(isDragging, () => {
      if (!isDragging) {
        startedDrag.value = false;
      }
    });
    watchEffect(() => {
      if (startedDrag.value) {
        transformX.value =
          preTransformX.value +
          Math.min(
            Math.max(dragRect.value.left, x.value),
            dragRect.value.right
          ) -
          startX.value;
        transformY.value =
          preTransformY.value +
          Math.min(
            Math.max(dragRect.value.top, y.value),
            dragRect.value.bottom
          ) -
          startY.value;
      }
    });
    const transformStyle = computed(() => {
      return {
        transform: `translate(${transformX.value}px, ${transformY.value}px)`,
      };
    });

    const okLayout = () => {
      visible.value = false;
    };

    const cancelLayout = () => {
      visible.value = false;
    };


    onMounted(() => { });

    return {
      comItems,
      comActive,
      editLayout,
      visible,
      showModal,
      handleOk,
      modalTitleRef,
      transformStyle,
      okLayout,
      cancelLayout,
    };
  },
});
</script>
/*
 * page组件，所有组件配置
 */
import * as Icons from "./icon";

export default {
    list: [
        {
            name: "媒体组件",
            key: "media",
            data: [
                {
                    name: "轮播图",
                    type: "banner",
                    icon: Icons.banner,
                },
                {
                    name: "图片",
                    type: "image",
                    icon: Icons.image,
                },
                {
                    name: "图片橱窗",
                    type: "window",
                    icon: Icons.window,
                },
                {
                    name: "视频",
                    type: "video",
                    icon: Icons.video,
                },
                {
                    name: "文章",
                    type: "article",
                    icon: Icons.article,
                },
            ],
        },
        {
            name: "商城组件",
            key: "store",
            data: [
                {
                    name: "搜索框",
                    type: "search",
                    icon: Icons.search,
                },
                {
                    name: "店铺公告",
                    type: "notice",
                    icon: Icons.notice,
                },
                {
                    name: "导航",
                    type: "navBar",
                    icon: Icons.navBar,
                },
                {
                    name: "商品",
                    type: "goods",
                    icon: Icons.goods,
                },
                {
                    name: "在线客服",
                    type: "service",
                    icon: Icons.service,
                },
            ],
        },
        {
            name: "其他组件",
            key: "other",
            data: [
                {
                    name: "富文本",
                    type: "richText",
                    icon: Icons.richText,
                },
                {
                    name: "辅助空白",
                    type: "blank",
                    icon: Icons.blank,
                },
                {
                    name: "辅助线",
                    type: "guide",
                    icon: Icons.guide,
                },
            ],
        },
    ]
};

<template>
  <div class="design-editor">
    <!-- 标题 -->
    <div class="editor-title">
      <span>{{ data.curItem.name }}{{ data.selectedIndex }}</span>
    </div>
    <div class="editor-body">
      <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
        <div v-if="data.curItem.type === 'page'">
          <a-tabs centered>
            <a-tab-pane key="1" tab="数据设置">
              <div class="block-box">
                <div class="block-title">基本信息</div>

                <a-form-item label="页面名称">
                  <a-input v-model:value="info.page.params.name" />
                  <div class="tips">页面名称仅用于后台管理</div></a-form-item
                ><a-form-item label="标题名称">
                  <a-input v-model:value="info.page.params.title" />
                  <div class="tips">用户端端顶部显示的标题</div></a-form-item
                ><a-form-item label="分享标题">
                  <a-input v-model:value="info.page.params.shareTitle" />
                  <div class="tips">用户端转发时显示的标题</div></a-form-item
                >
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="样式设置">
              <div class="block-box">
                <div class="block-title">基本信息</div>
                <a-form-item label="标题颜色">
                  <div class="block-right">
                    <a-radio-group
                      button-style="solid"
                      v-model:value="info.page.style.titleTextColor"
                    >
                      <a-radio-button value="#ffffff">白色</a-radio-button>
                      <a-radio-button value="#000000">黑色</a-radio-button>
                    </a-radio-group>
                  </div>
                </a-form-item>
                <a-form-item label="标题背景">
                  <div class="block-right">
                    <span class="color-select-code"
                      >Hex：{{ info.page.style.titleBackgroundColor }}</span
                    >
                    <ColorPicker
                      picker-type="chrome"
                      format="hex"
                      v-model:pureColor="info.page.style.titleBackgroundColor"
                    />
                    <span class="color-reset" @click="resetColor">重置</span>
                    <!-- 组件文档 https://www.npmjs.com/package/vue3-colorpicker -->
                  </div>
                </a-form-item>
                <a-form-item label="页面背景">
                  <div class="block-right">
                    <span class="color-select-code"
                      >Hex：{{ info.page.style.backgroundColor }}</span
                    >
                    <ColorPicker
                      picker-type="chrome"
                      format="hex"
                      v-model:pureColor="info.page.style.backgroundColor"
                    />
                    <span class="color-reset" @click="resetColor">重置</span>
                    <!-- 组件文档 https://www.npmjs.com/package/vue3-colorpicker -->
                  </div>
                </a-form-item>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
        <div v-else-if="data.curItem.type === 'banner'">
          <a-tabs centered>
            <a-tab-pane key="1" tab="数据设置">
              <div class="sub-title">添加图片 (最多10张，可拖动排序）</div>
              <div
                class="block-box"
                v-for="(item, index) in info.items[info.selectedIndex].data"
                :key="index"
              >
                <div class="block-title">
                  <span class="title-left">图片</span
                  ><a
                    class="delete-item"
                    @click="handleDeleleData(data.curItem, index)"
                    >删除</a
                  >
                </div>
                <a-row type="flex" justify="space-between" align="bottom">
                  <a-col :span="12">
                    <div class="block-item">
                      <span class="label">图片</span
                      ><span class="label">{{ item.imgName }}</span>
                    </div>
                    <div class="block-item">
                      <span class="label">链接</span
                      ><span class="label">
                        <CsLinks />
                      </span>
                    </div>
                  </a-col>
                  <a-col :span="4">
                    <CsImage
                      v-model:imgOne="
                        info.items[info.selectedIndex].data[index]
                      "
                    />
                  </a-col>
                </a-row>
              </div>
              <div class="add-image-btn" @click="updateItemData(5)">
                <PlusOutlined /><span class="add-image-btn-txt">添加图片</span>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="样式设置">
              <div class="block-box">
                <div class="block-title">组件样式</div>
                <a-form-item label="上下边距">
                  <a-slider
                    v-model:value="
                      info.items[data.selectedIndex].style.marginTopBottom
                    "
                  />
                </a-form-item>
                <a-form-item label="左右边距">
                  <a-slider
                    v-model:value="
                      info.items[data.selectedIndex].style.marginLeftRight
                    "
                  />
                </a-form-item>
                <a-form-item label="高度">
                  <a-slider
                    :min="130"
                    :max="600"
                    v-model:value="info.items[data.selectedIndex].style.height"
                  />
                </a-form-item>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
        <div v-else-if="data.curItem.type === 'image'">
          <a-tabs centered>
            <a-tab-pane key="1" tab="数据设置">
              <div class="sub-title">添加图片 (最多10张，可拖动排序）</div>
              <div
                class="block-box"
                v-for="(item, index) in info.items[info.selectedIndex].data"
                :key="index"
              >
                <div class="block-title">
                  <span class="title-left">图片</span
                  ><a
                    class="delete-item"
                    @click="handleDeleleData(data.curItem, index)"
                    >删除</a
                  >
                </div>
                <a-row type="flex" justify="space-between" align="bottom">
                  <a-col :span="12">
                    <div class="block-item">
                      <span class="label">图片</span
                      ><span class="label">{{ item.imgName }}</span>
                    </div>
                    <div class="block-item">
                      <span class="label">链接</span
                      ><span class="label">
                        <CsLinks />
                      </span>
                    </div>
                  </a-col>
                  <a-col :span="4">
                    <CsImage
                      v-model:imgOne="
                        info.items[data.selectedIndex].data[index]
                      "
                    />
                  </a-col>
                </a-row>
              </div>
              <div class="add-image-btn" @click="updateItemData(5)">
                <PlusOutlined /><span class="add-image-btn-txt">添加图片</span>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="样式设置">
              <div class="block-box">
                <div class="block-title">组件样式</div>
                <a-form-item label="上下边距">
                  <a-slider
                    v-model:value="
                      info.items[data.selectedIndex].style.paddingTopBottom
                    "
                  />
                </a-form-item>
                <a-form-item label="左右边距">
                  <a-slider
                    v-model:value="
                      info.items[data.selectedIndex].style.paddingLeftRight
                    "
                  />
                </a-form-item>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
        <div v-else-if="data.curItem.type === 'window'">
          <a-tabs centered>
            <a-tab-pane key="1" tab="数据设置">
              <div class="sub-title">添加图片 (最多10张，可拖动排序）</div>
              <div
                class="block-box"
                v-for="(item, index) in info.items[info.selectedIndex].data"
                :key="index"
              >
                <div class="block-title">
                  <span class="title-left">图片</span
                  ><a
                    class="delete-item"
                    @click="handleDeleleData(data.curItem, index)"
                    >删除</a
                  >
                </div>
                <a-row type="flex" justify="space-between" align="bottom">
                  <a-col :span="12">
                    <div class="block-item">
                      <span class="label">图片</span
                      ><span class="label">{{ item.imgName }}</span>
                    </div>
                    <div class="block-item">
                      <span class="label">链接</span
                      ><span class="label">
                        <CsLinks />
                      </span>
                    </div>
                  </a-col>
                  <a-col :span="4">
                    <CsImage
                      v-model:imgOne="
                        info.items[data.selectedIndex].data[index]
                      "
                    />
                  </a-col>
                </a-row>
              </div>
              <div class="add-image-btn" @click="updateItemData(5)">
                <PlusOutlined /><span class="add-image-btn-txt">添加图片</span>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="样式设置">
              <div class="block-box">
                <div class="block-title">组件样式</div>
                <a-form-item label="上下边距">
                  <a-slider
                    v-model:value="
                      info.items[data.selectedIndex].style.paddingTopBottom
                    "
                  />
                </a-form-item>
                <a-form-item label="左右边距">
                  <a-slider
                    v-model:value="
                      info.items[data.selectedIndex].style.paddingLeftRight
                    "
                  />
                </a-form-item>
                <a-form-item label="页面背景">
                  <div class="block-right">
                    <span class="color-select-code"
                      >Hex：{{
                        info.items[data.selectedIndex].style.background
                      }}</span
                    >
                    <ColorPicker
                      picker-type="chrome"
                      format="hex"
                      v-model:pureColor="
                        info.items[data.selectedIndex].style.background
                      "
                    />
                    <span class="color-reset" @click="resetColor">重置</span>
                    <!-- 组件文档 https://www.npmjs.com/package/vue3-colorpicker -->
                  </div>
                </a-form-item>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
        <div v-else-if="data.curItem.type === 'video'">5</div>
        <div v-else-if="data.curItem.type === 'article'">
          <a-tabs centered>
            <a-tab-pane key="1" tab="数据设置">
              <div class="block-box">
                <div class="block-title">文章内容</div>
                <a-form-item label="显示数量">
                  <a-input-number
                    v-model:value="
                      info.items[data.selectedIndex].params.auto.showNum
                    "
                  />
                </a-form-item>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="样式设置">
              <div class="block-box">
                <div class="block-title">内容样式</div>
                <a-form-item label="上下外边距">
                  <a-slider
                    v-model:value="
                      info.items[data.selectedIndex].style.marginTopBottom
                    "
                  />
                </a-form-item>
                <a-form-item label="左右外边距">
                  <a-slider
                    v-model:value="
                      info.items[data.selectedIndex].style.marginLeftRight
                    "
                  />
                </a-form-item>
                <a-form-item label="上下内边距">
                  <a-slider
                    v-model:value="
                      info.items[data.selectedIndex].style.paddingTopBottom
                    "
                  />
                </a-form-item>
                <a-form-item label="左右内边距">
                  <a-slider
                    v-model:value="
                      info.items[data.selectedIndex].style.paddingLeftRight
                    "
                  />
                </a-form-item>
                <a-form-item label="封面圆角">
                  <a-slider
                    max="30"
                    v-model:value="
                      info.items[data.selectedIndex].style.coverRadius
                    "
                  />
                </a-form-item></div
            ></a-tab-pane>
          </a-tabs>
        </div>
        <div v-else-if="data.curItem.type === 'search'">
          <a-tabs centered>
            <a-tab-pane key="1" tab="数据设置">
              <div class="block-box">
                <div class="block-title">基本信息</div>
                <a-form-item label="提示文字">
                  <a-input
                    v-model:value="
                      info.items[info.selectedIndex].params.placeholder
                    "
                  />
                </a-form-item>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="样式设置">
              <div class="block-box">
                <div class="block-title">内容样式</div>
                <a-form-item label="搜索框样式">
                  <a-radio-group
                    button-style="solid"
                    v-model:value="
                      info.items[data.selectedIndex].style.searchStyle
                    "
                  >
                    <a-radio-button value="square">方形</a-radio-button>
                    <a-radio-button value="radius">圆角</a-radio-button>
                    <a-radio-button value="round">圆弧</a-radio-button>
                  </a-radio-group>
                </a-form-item>
                <a-form-item label="文字对齐">
                  <a-radio-group
                    button-style="solid"
                    v-model:value="
                      info.items[data.selectedIndex].style.textAlign
                    "
                  >
                    <a-radio-button value="left">居左</a-radio-button>
                    <a-radio-button value="center">居中</a-radio-button>
                    <a-radio-button value="right">居右</a-radio-button>
                  </a-radio-group>
                </a-form-item>
                <a-form-item label="上下边距">
                  <a-slider
                    v-model:value="
                      info.items[data.selectedIndex].style.paddingTopBottom
                    "
                  />
                </a-form-item>
                <a-form-item label="左右边距">
                  <a-slider
                    v-model:value="
                      info.items[data.selectedIndex].style.paddingLeftRight
                    "
                  />
                </a-form-item></div
            ></a-tab-pane>
          </a-tabs>
        </div>
        <div v-else-if="data.curItem.type === 'notice'">
          <a-tabs centered>
            <a-tab-pane key="1" tab="数据设置">
              <div class="block-box">
                <div class="block-title">基本信息</div>

                <a-form-item label="内容">
                  <a-input
                    v-model:value="info.items[data.selectedIndex].params.text"
                  />
                </a-form-item>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="样式设置">
              <div class="block-box">
                <div class="block-title">组件样式</div>
                <a-form-item label="背景颜色">
                  <div class="block-right">
                    <span class="color-select-code"
                      >Hex：{{
                        info.items[data.selectedIndex].style.background
                      }}</span
                    >
                    <ColorPicker
                      picker-type="chrome"
                      format="hex"
                      v-model:pureColor="
                        info.items[data.selectedIndex].style.background
                      "
                    />
                    <span class="color-reset" @click="resetColor">重置</span>
                    <!-- 组件文档 https://www.npmjs.com/package/vue3-colorpicker -->
                  </div>
                </a-form-item>
                <a-form-item label="文字颜色">
                  <div class="block-right">
                    <span class="color-select-code"
                      >Hex：{{
                        info.items[data.selectedIndex].style.textColor
                      }}</span
                    >
                    <ColorPicker
                      picker-type="chrome"
                      format="hex"
                      v-model:pureColor="
                        info.items[data.selectedIndex].style.textColor
                      "
                    />
                    <span class="color-reset" @click="resetColor">重置</span>
                    <!-- 组件文档 https://www.npmjs.com/package/vue3-colorpicker -->
                  </div>
                </a-form-item>
                <a-form-item label="上下边距">
                  <a-slider
                    v-model:value="
                      info.items[data.selectedIndex].style.marginTopBottom
                    "
                  />
                </a-form-item>
                <a-form-item label="左右边距">
                  <a-slider
                    v-model:value="
                      info.items[data.selectedIndex].style.marginLeftRight
                    "
                  />
                </a-form-item>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
        <div v-else-if="data.curItem.type === 'navBar'"></div>
        <div v-else-if="data.curItem.type === 'goods'"></div>
        <div v-else-if="data.curItem.type === 'service'"></div>
        <div v-else-if="data.curItem.type === 'richText'">
          <a-tabs centered>
            <a-tab-pane key="1" tab="数据设置">
              <div class="block-box">
                <div class="block-title">富文本</div>
                <a-textarea
                  v-model:value="info.items[data.selectedIndex].params.content"
                  :rows="16"
                />
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="样式设置">
              <div class="block-box">
                <div class="block-title">组件样式</div>
                <a-form-item label="背景颜色">
                  <div class="block-right">
                    <span class="color-select-code"
                      >Hex：{{
                        info.items[data.selectedIndex].style.background
                      }}</span
                    >
                    <ColorPicker
                      picker-type="chrome"
                      format="hex"
                      v-model:pureColor="
                        info.items[data.selectedIndex].style.background
                      "
                    />
                    <span class="color-reset" @click="resetColor">重置</span>
                    <!-- 组件文档 https://www.npmjs.com/package/vue3-colorpicker -->
                  </div>
                </a-form-item>
                <a-form-item label="上下边距">
                  <a-slider
                    v-model:value="
                      info.items[data.selectedIndex].style.marginTopBottom
                    "
                  />
                </a-form-item>
                <a-form-item label="左右边距">
                  <a-slider
                    v-model:value="
                      info.items[data.selectedIndex].style.marginLeftRight
                    "
                  />
                </a-form-item>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
        <div v-else-if="data.curItem.type === 'blank'">
          <div class="block-box">
            <a-form-item label="文字颜色">
              <div class="block-right">
                <span class="color-select-code"
                  >Hex：{{
                    info.items[data.selectedIndex].style.background
                  }}</span
                >
                <ColorPicker
                  picker-type="chrome"
                  format="hex"
                  v-model:pureColor="
                    info.items[data.selectedIndex].style.background
                  "
                />
                <span class="color-reset" @click="resetColor">重置</span>
                <!-- 组件文档 https://www.npmjs.com/package/vue3-colorpicker -->
              </div>
            </a-form-item>
            <a-form-item label="上下边距">
              <a-slider
                :max="200"
                v-model:value="info.items[data.selectedIndex].style.height"
              />
            </a-form-item>
          </div>
        </div>
        <div v-else-if="data.curItem.type === 'guide'">
          <div class="block-box">
            <a-form-item label="线条样式">
              <div class="block-right">
                <a-radio-group
                  button-style="solid"
                  v-model:value="info.items[data.selectedIndex].style.lineStyle"
                >
                  <a-radio-button value="solid">实线</a-radio-button>
                  <a-radio-button value="dashed">虚线</a-radio-button>
                  <a-radio-button value="dotted">点状</a-radio-button>
                </a-radio-group>
              </div>
            </a-form-item>
            <a-form-item label="背景颜色">
              <div class="block-right">
                <span class="color-select-code"
                  >Hex：{{
                    info.items[data.selectedIndex].style.background
                  }}</span
                >
                <ColorPicker
                  picker-type="chrome"
                  format="hex"
                  v-model:pureColor="
                    info.items[data.selectedIndex].style.background
                  "
                />
                <span class="color-reset" @click="resetColor">重置</span>
                <!-- 组件文档 https://www.npmjs.com/package/vue3-colorpicker -->
              </div>
            </a-form-item>
            <a-form-item label="线条颜色">
              <div class="block-right">
                <span class="color-select-code"
                  >Hex：{{
                    info.items[data.selectedIndex].style.lineColor
                  }}</span
                >
                <ColorPicker
                  picker-type="chrome"
                  format="hex"
                  v-model:pureColor="
                    info.items[data.selectedIndex].style.lineColor
                  "
                />
                <span class="color-reset" @click="resetColor">重置</span>
                <!-- 组件文档 https://www.npmjs.com/package/vue3-colorpicker -->
              </div>
            </a-form-item>
            <a-form-item label="线条高度">
              <a-slider
                :min="1"
                :max="20"
                v-model:value="info.items[data.selectedIndex].style.lineHeight"
              />
            </a-form-item>
            <a-form-item label="上下间距">
              <a-slider
                :min="0"
                :max="200"
                v-model:value="
                  info.items[data.selectedIndex].style.paddingTopBottom
                "
              />
            </a-form-item>
          </div>
        </div>
      </a-form>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed } from "vue";
import { PlusOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { ColorPicker } from "vue3-colorpicker";
import CsImage from "@/components/UpImage";
import CsLinks from "@/components/Links";
import "vue3-colorpicker/style.css";

import "./style/editor.less";
export default defineComponent({
  props: {
    data: { type: Object, default: () => {} },
  },
  components: { PlusOutlined, ColorPicker, CsImage, CsLinks },
  setup(props, { emit }) {
    // const info = computed(() => props.data);

    const info = computed({
      get: () => props.data,
      set: (value) => emit("update:value", value),
    });

    const resetColor = () => {};

    // #组件内添加元素
    const updateItemData = (val) => {
      console.log(info.value);
      emit("updateItemData", val);
    };
    // 删除组件内元素
    const handleDeleleData = (curItem, index) => {
      console.log(curItem);
      if (curItem.data.length <= 1) {
        message.warning("至少保留一个");
        return false;
      }
      curItem.data.splice(index, 1);
    };

    return {
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 16,
      },
      info,
      resetColor,
      updateItemData,
      handleDeleleData,
    };
  },
});
</script>
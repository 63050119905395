<template>
  <div class="design-body">
    <a-row>
      <a-col :span="6">
        <Comodule @handleClickItem="onAddItem" />
      </a-col>
      <a-col :span="12">
        <Phone @switchModule="onActiveEditer" @deleleModule="onDeleleModule" :data="data" />
      </a-col>
      <a-col :span="6">
        <Editor @updateItemData="changeItemsData" :data="data" />
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { defineComponent, onBeforeMount, reactive } from "vue";
import { Comodule, Phone, Editor } from "./modules";
import phoneConfig from "./config/phone";

import defaultData from "@/components/Page/default_design.json";

export default defineComponent({
  components: {
    Comodule,
    Phone,
    Editor,
  },
  setup() {
    // 初始化编辑器数据 page:页面数据，items:组件列表数据
    const data = reactive({
      page: {},
      items: [],
      curItem: {},
      selectedIndex: -1,
    });

    // 页面编辑器-设置当前选中的组件索引-切换选中的组件
    const onActiveEditer = (index) => {
      data.selectedIndex = index;
      data.curItem = index === -1 ? data.page : data.items[index];
    };

    // 删除模块
    const onDeleleModule = (index) => {
      data.items.splice(index, 1);
      onActiveEditer(-1);
    };

    // 向页面添加组件
    const onAddItem = (type) => {
      data.items.push(phoneConfig.items[type]);
      onActiveEditer(data.items.length - 1);
    };

    //添加修改数据
    const changeItemsData = () => {
      let itemVal = phoneConfig.items[data.curItem.type].defaultData;
      data.items[data.selectedIndex].data.push(itemVal);
    };

    // 设置默认值、默认选中页面设置
    onBeforeMount(() => {
      data.page = defaultData.page;
      data.items = defaultData.items;
      onActiveEditer(-1);
    });

    return { onAddItem, data, onActiveEditer, onDeleleModule, changeItemsData };
  },
});
</script>
<template>
  <div class="rich-content">
    大江东去，浪淘尽，千古风流人物。故垒西边，人道是，三国周郎赤壁。乱石穿空，惊涛拍岸，卷起千堆雪。江山如画，一时多少豪杰。遥想公瑾当年，小乔初嫁了，雄姿英发。羽扇纶巾，谈笑间，樯橹灰飞烟灭。故国神游，多情应笑我，早生华发。人生如梦，一尊还酹江月。
  </div>
</template>
<style lang="less" scoped>
.rich-content {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
  font-size: 14px;
  color: #000000d9;
  overflow-wrap: break-word;
  text-indent: 24px;
  line-height: 40px;
  padding: 10px;
  border: 1px solid #f1f1f1;
}
</style>
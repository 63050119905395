<template>
  <a @click="handleSelectLink">{{ name }}</a>
  <a-modal
    v-model:visible="links_visible"
    width="800px"
    @ok="update"
    title="地址库"
  >
    <div style="height: 500px"></div>
  </a-modal>
</template>
<script>
import { defineComponent, ref } from "vue";
import "./style/links.less";
export default defineComponent({
  name: "CsLinks",
  props: {
    name: { type: String, default: "链接地址" },
  },
  emits: ["update"],
  setup(ctx) {
    // console.log(props);
    const links_visible = ref(false);

    const handleSelectLink = () => {
      links_visible.value = !links_visible.value;
    };

    const update = () => {
      links_visible.value = false;
      ctx.emit("update", "地址选择回调成功");
    };

    return { links_visible, handleSelectLink, update };
  },
});
</script>
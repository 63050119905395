<template>
  <div class="design-comodule">
    <a-collapse v-model:activeKey="activeKey" ghost>
      <a-collapse-panel
        v-for="item in components"
        :key="item.key"
        :header="item.name"
      >
        <a-row>
          <a-col
            :span="8"
            v-for="(itemChild, index) in item.data"
            :key="index"
            @click="handleClickItem(itemChild.type)"
          >
            <div class="module-item">
              <a-image :width="28" :src="itemChild.icon" :preview="false" />
              <p class="module-name">{{ itemChild.name }}</p>
            </div>
          </a-col></a-row
        >
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>
<script>
import { defineComponent, ref } from "vue";
import componentList from "./module";
import "./style/comodule.less";

export default defineComponent({
  components: {},
  props: {},
  setup(props, context) {
    const activeKey = ref(["media", "store", "other"]);
    const handleClickItem = (type) => {
      // // 通知父组件刷新列表
      context.emit("handleClickItem", type);
    };

    const test = () => {
      console.log(props);
    };

    return {
      components: componentList.list,
      handleClickItem,
      activeKey,
      test,
    };
  },
});
</script>
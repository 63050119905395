<template>
  <div class="advs-box">
    <a-carousel>
      <div v-for="item in data.fromData" :key="item">
        <div class="advs-item">
          <a-image :preview="false" :src="item" :style="{width:style.width,height:style.height}" />
        </div>
      </div>
    </a-carousel>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  components: {},
  props: {
    data: Object,
    style: Object,
  },
  setup(props) {
    console.log(props.data);
  },
});
</script>
<style lang="less" scoped>
.advs-box {
  width: 1200px;
  text-align: center;
  .advs-item {
    background: #364d79;
  }
}
</style>
export default {
    page: {
        name: "页面设置",
        type: "page",
        params: {
            name: "页面名称",
            title: "页面标题",
            shareTitle: "分享标题"
        },
        style: {
            titleTextColor: "#000000",
            titleBackgroundColor: "#ffffff",
            backgroundColor: "#f1f1f1"
        }
    },
    items: {
        search: {
            name: "搜索框",
            type: "search",
            params: {
                placeholder: "请输入关键字进行搜索"
            },
            style: {
                textAlign: "left",
                searchStyle: "square",
                paddingTopBottom: 0,
                paddingLeftRight: 0
            }
        },
        banner: {
            name: "图片轮播",
            type: "banner",
            style: {
                marginTopBottom: 0,
                marginLeftRight: 0,
                height: 200,
                btnColor: "#ffffff",
                btnShape: "round",
                interval: 2.5
            },
            defaultData: {
                imgUrl: "http://120.79.20.209/static/image/default_cover.png",
                imgName: "默认图",
                link: null
            },
            data: [
                {
                    imgUrl: "http://120.79.20.209/static/image/default_cover.png",
                    imgName: "默认图",
                    link: null
                },
                {
                    imgUrl: "http://120.79.20.209/static/image/default_cover.png",
                    imgName: "默认图",
                    link: null
                }
            ]
        },
        image: {
            name: "图片",
            type: "image",
            style: {
                paddingTopBottom: 0,
                paddingLeftRight: 0,
                background: "#ffffff"
            },
            defaultData: {
                imgUrl: "http://120.79.20.209/static/image/default_cover.png",
                imgName: "默认图",
                link: null
            },
            data: [
                {
                    imgUrl: "http://120.79.20.209/static/image/default_cover.png",
                    imgName: "默认图",
                    link: null
                }
            ]
        },
        navBar: {
            name: "导航组",
            type: "navBar",
            style: {
                rowsNum: 4,
                background: "#ffffff",
                paddingTop: 0,
                textColor: "#666666"
            },
            data: [
                {
                    imgUrl: "http://yoshop2.com/assets/store/img/diy/navbar/01.png",
                    imgName: "icon-1.png",
                    link: null,
                    text: "按钮文字1"
                },
                {
                    imgUrl: "http://yoshop2.com/assets/store/img/diy/navbar/01.png",
                    imgName: "icon-2.jpg",
                    link: null,
                    text: "按钮文字2"
                },
                {
                    imgUrl: "http://yoshop2.com/assets/store/img/diy/navbar/01.png",
                    imgName: "icon-3.jpg",
                    link: null,
                    text: "按钮文字3"
                },
                {
                    imgUrl: "http://yoshop2.com/assets/store/img/diy/navbar/01.png",
                    imgName: "icon-4.jpg",
                    link: null,
                    text: "按钮文字4"
                }
            ]
        },
        blank: {
            name: "辅助空白",
            type: "blank",
            style: {
                height: 20,
                background: "#ffffff"
            }
        },
        guide: {
            name: "辅助线",
            type: "guide",
            style: {
                background: "#ffffff",
                lineStyle: "solid",
                lineHeight: 1,
                lineColor: "#000000",
                paddingTopBottom: 10
            }
        },
        video: {
            name: "视频组",
            type: "video",
            params: {
                videoUrl: "http://wxsnsdy.tc.qq.com/105/20210/snsdyvideodownload?filekey=30280201010421301f0201690402534804102ca905ce620b1241b726bc41dcff44e00204012882540400.mp4",
                poster: "http://yoshop2.com/assets/store/img/diy/video_poster.png",
                autoplay: 0
            },
            style: {
                paddingTop: 0,
                height: 190
            }
        },
        article: {
            name: "文章组",
            type: "article",
            params: {
                source: "auto",
                auto: {
                    category: -1,
                    showNum: 6
                }
            },
            style: {
                marginTopBottom: 10,
                marginLeftRight: 0,
                paddingTopBottom: 10,
                paddingLeftRight: 10,
                coverRadius: 0
            },
            data: [
                {
                    title: "此处显示文章标题",
                    show_type: 10,
                    image: "http://yoshop2.com/assets/store/img/diy/article/01.png",
                    views_num: 309
                },
                {
                    title: "此处显示文章标题",
                    show_type: 10,
                    image: "http://yoshop2.com/assets/store/img/diy/article/01.png",
                    views_num: 309
                }
            ]
        },
        notice: {
            name: "公告",
            type: "notice",
            params: {
                text: "这里是第一条自定义公告的标题",
                link: null,
                showIcon: true,
                scrollable: true
            },
            style: {
                marginTopBottom: 0,
                marginLeftRight: 0,
                background: "#ffffff",
                textColor: "#e32424"
            }
        },
        richText: {
            name: "富文本",
            type: "richText",
            params: {
                content: "<p>这里是文本的内容</p>"
            },
            style: {
                marginTopBottom: 0,
                marginLeftRight: 0,
                background: "#ffffff"
            }
        },
        window: {
            name: "图片橱窗",
            type: "window",
            style: {
                marginTopBottom: 0,
                marginLeftRight: 0,
                background: "#ffffff",
                layout: 2
            },
            data: [
                {
                    imgUrl: "http://yoshop2.com/assets/store/img/diy/window/01.jpg",
                    link: null
                },
                {
                    imgUrl: "http://yoshop2.com/assets/store/img/diy/window/02.jpg",
                    link: null
                },
                {
                    imgUrl: "http://yoshop2.com/assets/store/img/diy/window/03.jpg",
                    link: null
                },
                {
                    imgUrl: "http://yoshop2.com/assets/store/img/diy/window/04.jpg",
                    link: null
                }
            ],
            dataNum: 4
        },
        goods: {
            name: "商品组",
            type: "goods",
            params: {
                source: "auto",
                auto: {
                    category: 0,
                    goodsSort: "all",
                    showNum: 6
                }
            },
            style: {
                background: "#F6F6F6",
                display: "list",
                column: 2,
                show: [
                    "goodsName",
                    "goodsPrice",
                    "linePrice",
                    "sellingPoint",
                    "goodsSales"
                ]
            },
            defaultData: [
                {
                    goods_name: "此处显示商品名称",
                    goods_image: "http://yoshop2.com/assets/store/img/diy/goods/01.png",
                    goods_price_min: "99.00",
                    line_price_min: "139.00",
                    selling_point: "此款商品美观大方 不容错过",
                    goods_sales: 100
                },
                {
                    goods_name: "此处显示商品名称",
                    goods_image: "http://yoshop2.com/assets/store/img/diy/goods/01.png",
                    goods_price_min: "99.00",
                    line_price_min: "139.00",
                    selling_point: "此款商品美观大方 不容错过",
                    goods_sales: 100
                },
                {
                    goods_name: "此处显示商品名称",
                    goods_image: "http://yoshop2.com/assets/store/img/diy/goods/01.png",
                    goods_price_min: "99.00",
                    line_price_min: "139.00",
                    selling_point: "此款商品美观大方 不容错过",
                    goods_sales: 100
                },
                {
                    goods_name: "此处显示商品名称",
                    goods_image: "http://yoshop2.com/assets/store/img/diy/goods/01.png",
                    goods_price_min: "99.00",
                    line_price_min: "139.00",
                    selling_point: "此款商品美观大方 不容错过",
                    goods_sales: 100
                }
            ],
            data: [
                {
                    goods_name: "此处显示商品名称",
                    goods_image: "http://yoshop2.com/assets/store/img/diy/goods/01.png",
                    goods_price_min: "99.00",
                    line_price_min: "139.00",
                    selling_point: "此款商品美观大方 不容错过",
                    goods_sales: 100,
                    is_default: true
                },
                {
                    goods_name: "此处显示商品名称",
                    goods_image: "http://yoshop2.com/assets/store/img/diy/goods/01.png",
                    goods_price_min: "99.00",
                    line_price_min: "139.00",
                    selling_point: "此款商品美观大方 不容错过",
                    goods_sales: 100,
                    is_default: true
                }
            ]
        },
        service: {
            name: "在线客服",
            type: "service",
            params: {
                type: "chat",
                image: "http://yoshop2.com/assets/store/img/diy/service.png",
                tel: ""
            },
            style: {
                right: 1,
                bottom: 10,
                opacity: 100
            }
        }
    }
};